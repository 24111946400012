import { CodigoDescricaoDTO } from '@/dtos/CodigoDescricaoDTO';
import { CodigoDescricaoTurnoDTO } from '@/dtos/CodigoDescricaoTurnoDTO';
import { eTurno } from '@/enums/eTurno';
import { IResultApi } from '@/interfaces/IResultApi';
import { http, cacheHttp } from '@/request/index';

export function getAllEscolas() {
  const url = `escolas/getescolas`;
  return cacheHttp.get<CodigoDescricaoTurnoDTO[]>(url);
}

export function getEscolasPreMatriculaComCalculoDeDistancia(cep: string | null, preMatriculaId: number, serieId: number | null, turno: eTurno, candidatoId: number | null) {
  const url = `escolas/cep/${cep}/${preMatriculaId}/${serieId}/${turno}/${candidatoId}`;
  return http.get<CodigoDescricaoTurnoDTO[]>(url);
}

export function getEscolasPreferenciais(preMatriculaId: number, serieId: number, municipioId: number | null, candidatoId: number | null) {
  const url = `escolas/preferencial/${preMatriculaId}/${serieId}/${municipioId}/${candidatoId}`;
  return http.get<CodigoDescricaoTurnoDTO[]>(url);
}

export function getEscolasPreMatriculaPorTurno(preMatriculaId: number, serieId: number, turno: eTurno | null, municipioId?: number | null, candidatoId?: number | null) {
  const url = `escolas/preferencial/porturno/${preMatriculaId}/${serieId}/${turno}/${municipioId}/${candidatoId}`;
  return http.get<CodigoDescricaoTurnoDTO[]>(url);
}

export function obtenhaEscolasParaConsulta(parametros: {}) {
  const url = `escolas/pesquisar`;
  return http.post<IResultApi>(url, parametros);
}

export function obtenhaEscolasParaSolicitacaoDeTransferencia(serieId: number, escolaId: number, turno: eTurno | null, excedente: boolean) {
  const url = `escolas/solicitacaodetransferencia/${escolaId}/${serieId}?turno=${turno}&excedente=${(excedente ? 'true' : 'false')}`;
  return http.get<IResultApi>(url);
}

export function obtenhaEscolasDaMatricula(preMatriculaId: number) {
  const url = `escolas/pormatricula/${preMatriculaId}`;
  return cacheHttp.get<CodigoDescricaoDTO[]>(url);
}

export function obtenhaTodasAsEscolasPreferenciais(preMatriculaId: number, serieId: number, turno: eTurno | null, municipioId?: number | null) {
  const url = `escolas/preferencial/todasasescolas/${preMatriculaId}/${serieId}/${turno}/${municipioId}`;
  return http.get<CodigoDescricaoTurnoDTO[]>(url);
}