import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { UnidadeConsulta } from "@/dtos/UnidadeConsulta";
import { eTurno, TurnoLabel, TodosOsTurnos } from "@/enums/eTurno";
import cidadeService from "@/services/cidadeService";
import { obtenhaEscolasParaConsulta } from "@/services/escolaService";
import { obtenhaSeriesAtivas } from "@/services/serieAppService";
import { alertWarning, closeLoading, onFilterString, showLoading } from "@/utils/functions";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ConsultaUnidadesPartial extends Vue {
    bairros = new Array<string>();
    unidades = new Array<UnidadeConsulta>();
    series = new Array<CodigoDescricaoDTO>();
    turnos = TodosOsTurnos;

    bairro = '';
    turno: eTurno | null = null;
    serieId: CodigoDescricaoDTO | null = null;
    termoDePesquisa = '';
    exibirUnidades = false;

    constructor() {
        super();

        cidadeService.getBairros()
            .then((res) => {
                res.data.forEach(bairro => {
                    this.bairros.push(bairro);
                });
            });

        obtenhaSeriesAtivas()
            .then(res => {
                res.data.forEach(serie => {
                    this.series.push(serie);
                });
            });
    }

    filtre(item, queryText): boolean {
        return onFilterString(item, queryText);
    }

    redicionarParaMapa(unidade: UnidadeConsulta) {
        let url = 'http://www.google.com/maps';
        if (unidade.latitude && unidade.longitude) {
            url += `/place/${unidade.latitude},${unidade.longitude}`;
        } else {
            url += `?q=${unidade.nome}`;
            if (unidade.bairro) {
                url += `,${unidade.bairro}`;
            }
            if (unidade.cidade) {
                url += `,${unidade.cidade}`;
            }
        }
        window.open(url, '_blank');
    }

    async pesquise() {
        this.unidades = new Array<UnidadeConsulta>();

        showLoading('Buscando unidades...');
        setTimeout(async () => {
            const parametros = {
                bairro: this.bairro,
                turno: this.turno,
                serieId: this.serieId?.codigo,
                termoDePesquisa: this.termoDePesquisa
            };
    
            await obtenhaEscolasParaConsulta(parametros)
                .then(res => {
                    const data = res.data;
                    if (data.sucesso) {
                        data.dados.escolas.forEach(unidade => {
                            this.unidades.push(unidade);
                        });
                    } else {
                        alertWarning(data.mensagem);
                    }
                })
                .catch(err => {
                    alertWarning(err.data);
                })
                .finally(() => {
                    closeLoading();
                });
    
            this.exibirUnidades = true;
        }, 1000);
    }

    getTurnoDescription(item: eTurno) {
        return TurnoLabel.get(item);
    }
}