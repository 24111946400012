import { CodigoDescricaoDTO } from "@/dtos/CodigoDescricaoDTO";
import { DadosSocioeconomicosDTO } from "@/dtos/DadosSocioeconomicosDTO";
import { eParentesco, ParentescoLabel } from "@/enums/eParentesco";
import { eSexo, SexoLabel } from "@/enums/eSexo";
import { eTipoDeTrabalhoMae, TipoDeTrabalhoMaeLabel } from "@/enums/eTipoDeTrabalhoMae";
import { eZona, ZonaLabel } from "@/enums/eZona";
import { CandidatoCadastroAntecipado } from "@/models/CandidatoCadastroAntecipado";
import router from "@/router";
import cadastroAntecipadoService from "@/services/cadastroAntecipadoService";
import candidatoService from "@/services/candidatoService";
import { getEndereco } from "@/services/cepService";
import cidadeService from "@/services/cidadeService";
import dadosSocioeconomicosService from "@/services/dadosSocioeconomicosService";
import localStorageService from "@/services/localStorageService";
import { alertError, alertInfo, alertWarning, closeLoading, confirmSucess, formatDate, getEstados, onFilter, onFilterString, possuiValor, showLoading } from "@/utils/functions";
import rules from "@/utils/rules";
import { Component, Vue } from "vue-property-decorator";
import ModalAnexarDocumento from "../components/ModalAnexarDocumento.vue";
import ListaDeDocumentos from "../components/ListaDeDocumentos.vue";
import callCenterService from "@/services/callCenterService";
import { eTipoFinalizacaoAtendimento } from "@/enums/eTipoFinalizacaoAtendimento";
import { Solicitante } from "@/models/Solicitante";
import { eTipoPesquisaProtocolo } from "@/enums/eTipoPesquisaProtocolo";
import { IEndereco } from '@/interfaces/IEndereco';

@Component({
    components: {
      ListaDeDocumentos,
      ModalAnexarDocumento,
    },
  })
export default class PreCadastro extends Vue {
    itemsDadosSocioEconomicos = new DadosSocioeconomicosDTO();
    dadosDoCadastroAntecipado;
    aceitaTermos = false;
    itemsSexo = [eSexo.Masculino, eSexo.Feminino];
    itemsParentesco = [
        eParentesco.Mae,
        eParentesco.Pai,
        eParentesco.Tio,
        eParentesco.Primo,
        eParentesco.Avo,
        eParentesco.Cunhado,
        eParentesco.Irmao,
        eParentesco.Madrasta,
        eParentesco.Padrasto,
        eParentesco.Outro,
    ];
    itemsTrabalhoMae = [
        eTipoDeTrabalhoMae.Autonoma,
        eTipoDeTrabalhoMae.Contracheque,
        eTipoDeTrabalhoMae.BPC,
        eTipoDeTrabalhoMae.NaoTrabalha
    ];
    itemsZona = [eZona.Rural, eZona.Urbana];
    bairros = new Array<string>();
    estados: string[] = getEstados();
    cidades: CodigoDescricaoDTO[] = new Array<CodigoDescricaoDTO>();

    candidato = new CandidatoCadastroAntecipado();

    formularioValido = false;
    regras = rules.obtenhaRegrasDeValidacaoDoCadastroAntecipado(this.candidato);

    documentos = this.$store.state.documentos;

    bloquearCpf = false;
    bloquearSus = false;
    bloquearNis = false;

    loading = false;

    configuracaoGeral = localStorageService.obtenhaConfiguracaoGeral();

    constructor () {
        super();
        dadosSocioeconomicosService.obtenhaValoresPossiveis(this.itemsDadosSocioEconomicos);

        cidadeService.getBairros()
            .then((res) => {
                res.data.forEach(bairro => {
                    this.bairros.push(bairro);
                });
            });

        this.dadosDoCadastroAntecipado = localStorageService.obtenhaDadosDoCadastroAntecipado();

        const solicitante: Solicitante = this.$store.state.solicitante;
        if (possuiValor(solicitante) && possuiValor(solicitante.nome)) {
        if(solicitante.ehOProprioCandidato){
            this.candidato.nome = solicitante.nome;
            this.candidato.telefonePrincipal = solicitante.telefone || '';
            this.candidato.telefoneSecundario = solicitante.telefoneSecundario || '';
            this.candidato.cpf = solicitante.cpf || '';
        } else {
            this.candidato.responsavelNome = solicitante.nome;
            this.candidato.responsavelTelefonePrincipal = solicitante.telefone || '';
            this.candidato.responsavelTelefoneSecundario = solicitante.telefoneSecundario || '';
            this.candidato.responsavelCpf = solicitante.cpf || '';
            if (solicitante.parentesco) {
                this.candidato.responsavelParentesco = solicitante.parentesco;
            }
        }
        }
    }

    mounted() {
        if (this.dadosDoCadastroAntecipado.mensagemInicial != null
            && this.dadosDoCadastroAntecipado.mensagemInicial != undefined
            && this.dadosDoCadastroAntecipado.mensagemInicial != '') {
            alertWarning(this.dadosDoCadastroAntecipado.mensagemInicial);
        }
    }

    salvar() {
        const validacao = (this.$refs.form as Vue & {
            validate: () => boolean;
        }).validate();
            
        if (validacao) {
            this.loading = true;
            this.candidato.documentos = this.documentos;

            const usuarioId = localStorageService.getItem('usuarioid');
            if (possuiValor(usuarioId) && usuarioId != 'null') {
                this.candidato.usuarioId = usuarioId ?? '';
            }

            this.candidato.solicitante = this.$store.state.solicitante;
            this.candidato.atendimentoCallCenterId = this.$store.state.atendimento.id;

            cadastroAntecipadoService.addCandidato(this.candidato, this.documentos)
            .then(res => {
                this.loading = false;
                const data = res.data;
                if (data.sucesso) {
                    this.$store.commit("clearState");

                    // NO FLUXO DE CALL CENTER, não haverá a opção de imprimir o protocolo
                    // Caso seja escolhido iniciar outro cadastro, mantém o solicitante no storage para seus dados aparecerem automaticamente na modal de solicitante
                    if(this.$store.state.atendimento.id){
                        confirmSucess(
                        "Cadastro",
                        "Candidato cadastrado com sucesso. <br> <b> Protocolo: " + data.dados.protocolo + "</b> <br> Deseja efetuar um novo cadastro antecipado ou prosseguir para a matrícula?"
                        ).then(async (result) => 
                        {
                            if (result.isDismissed)
                            {
                                callCenterService.finalizeAtendimento(this.$store.state.atendimento.id, eTipoFinalizacaoAtendimento.Automaticamente);
                                this.$store.commit("clearAtendimento");
                            }
                            router.push("/");
                        });
                    }
                    else // Fluxo padrão caso o cadastro não tenha sido feito pelo Call Center
                    {
                        confirmSucess(
                            "Cadastro",
                            data.mensagem
                          ).then(async (result) => 
                            {
                                // Confirmado imprimir protocolo
                                if (result.isConfirmed) 
                                {
                                    candidatoService.getProtocolo(eTipoPesquisaProtocolo.CadastroAntecipado, data.dados.protocolo);
                                }
                                router.push("/");
                            });
                    }
                } else {
                    alertWarning(data.mensagem);
                }
            })
            .catch(err => {
                this.loading = false;
                alertWarning(err.response.data);
            });

        } else {
            alertWarning("Existem dados obrigatórios não informados, por favor, revise todos os campos para dar continuidade ao cadastro.");
        }
    }

    getSexoDescription(item: eSexo) {
        return SexoLabel.get(item);
    }

    getParentescoDescription(item: eParentesco) {
        return ParentescoLabel.get(item);
    }

    getTipoDeTrabalhoDescription(item: eTipoDeTrabalhoMae) {
        return TipoDeTrabalhoMaeLabel.get(item);
    }

    changeParentesco(parentesco: eParentesco) {
        if (parentesco != eParentesco.Mae) {
            this.candidato.responsavelTipoDeTrabalhoMae = null;
        }
    }

    async buscaCep() {
        let cep = this.candidato.cep;
        if (!cep) return;
        cep = cep.replaceAll(".", "").replaceAll("-", "");
        if (cep.length < 8) return;
    
        showLoading("Carregando informações do CEP");
        setTimeout(async () => {
            await getEndereco(cep)
                .then(async (response) => {
                    const dados = response.data;
                    const endereco: IEndereco = dados.endereco;
                    this.cidades = dados.cidades;

                    this.candidato.complemento = endereco.complemento;
                    this.candidato.logradouro = endereco.logradouro;
                    this.candidato.estado = endereco.uf;
                    this.candidato.bairro = endereco.bairro;
                
                    if (endereco.cidade) {
                        const cid = endereco.cidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                        const cidade = this.cidades.filter(cd => cd.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase() == cid.toUpperCase());
                        if (cidade && cidade.length > 0) {
                            this.candidato.cidade = cidade[0];
                            this.$forceUpdate();
                        }
                    }

                    closeLoading();
                })
                .catch(err => {
                    closeLoading();
                });
        
        }, 1000);
    }

    getZonaDescription(item: eZona) {
        return ZonaLabel.get(item);
    }

    async popularCidades() {
        if (!this.candidato.estado) return;
        this.cidades = new Array<CodigoDescricaoDTO>();
        await cidadeService.getCidades(this.candidato.estado).then((response) => {
            this.cidades = response.data;
        }).catch((error) => {
            alertError(error.response.data);
        });
    }

    isNumber(evt: KeyboardEvent) {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    exibirVideo() {
        return  this.dadosDoCadastroAntecipado.urlVideoExplicativo != null
            && this.dadosDoCadastroAntecipado.urlVideoExplicativo != undefined
            && this.dadosDoCadastroAntecipado.urlVideoExplicativo != ''
            && this.dadosDoCadastroAntecipado.urlVideoExplicativo.length > 0;
    }

    openVideo() {
        window.open(this.dadosDoCadastroAntecipado.urlVideoExplicativo);
    }

    tipoDeTrabalhoIgualDeMae() {
        return this.candidato.responsavelParentesco == eParentesco.Mae;
    }

    filtre(item, queryText): boolean {
        return onFilter(item, queryText);
    }

    filtreString(item, queryText): boolean {
        return onFilterString(item, queryText);
    }

    changeCpf() {
        if (this.candidato.cpf) {
            showLoading("Validando cpf...");
            setTimeout(() => {
                cadastroAntecipadoService.existeCandidatoComCpf(this.candidato.cpf)
                    .then(res => {
                        if (!res.data.sucesso) {
                            alertWarning(res.data.mensagem);
                            this.$router.push('/')
                            return;
                        }

                        closeLoading();
                        this.populeCamposEmTela(res.data.mensagem, res.data.dados);
                    })
                    .catch(err => closeLoading());
            }, 1000);
        }
    }

    async populeCamposEmTela(mensagem: string, candidato: CandidatoCadastroAntecipado) {
        if (mensagem && mensagem != null && mensagem != '') {
            alertInfo(mensagem);
        }

        if (candidato != null && candidato != undefined) {
            this.candidato.nome = candidato.nome;
            this.candidato.cpf = candidato.cpf;
            this.candidato.cartaoSus = candidato.cartaoSus;
            this.candidato.nis = candidato.nis;
            this.candidato.sexo = candidato.sexo;
            this.candidato.dataNascimento = formatDate(candidato.dataNascimento) ?? '';
    
            this.candidato.telefonePrincipal = candidato.telefonePrincipal;
            this.candidato.telefoneSecundario = candidato.telefoneSecundario;
            this.candidato.possuiDeficiencia = candidato.possuiDeficiencia;
            this.candidato.possuiIrmaoGemeo = candidato.possuiIrmaoGemeo;
    
            this.candidato.cep = candidato.cep;
            this.candidato.estado = candidato.estado;
            this.candidato.logradouro = candidato.logradouro;
            this.candidato.complemento = candidato.complemento;
            this.candidato.numero = candidato.numero;
            this.candidato.bairro = candidato.bairro;
            this.candidato.pontoReferencia = candidato.pontoReferencia;
            this.candidato.zonaResidencia = candidato.zonaResidencia;
    
            this.candidato.responsavelNome = candidato.responsavelNome;
            this.candidato.responsavelCpf = candidato.responsavelCpf;
            this.candidato.responsavelTelefonePrincipal = candidato.responsavelTelefonePrincipal;
            this.candidato.responsavelTelefoneSecundario = candidato.responsavelTelefoneSecundario;
            this.candidato.responsavelParentesco = candidato.responsavelParentesco;
            this.candidato.responsavelTipoDeTrabalhoMae = candidato.responsavelTipoDeTrabalhoMae;
    
            if (candidato.rendaFamiliar != null) {
                this.candidato.rendaFamiliar = this.itemsDadosSocioEconomicos.rendaFamiliar.filter(c => c.codigo == candidato.rendaFamiliar.codigo)[0];
            }
            if (candidato.tipoDaMoradia != null) {
                this.candidato.tipoDaMoradia = this.itemsDadosSocioEconomicos.tiposDeMoradia.filter(c => c.codigo == candidato.tipoDaMoradia.codigo)[0];
            }
            if (candidato.situacaoDaMoradia != null) {
                this.candidato.situacaoDaMoradia = this.itemsDadosSocioEconomicos.situacoesDeMoradia.filter(c => c.codigo == candidato.situacaoDaMoradia.codigo)[0];
            }
            this.candidato.quantidadeDePessoasQueMoramNaMesmaCasa = candidato.quantidadeDePessoasQueMoramNaMesmaCasa;
            this.candidato.dependenciasFisicas = candidato.dependenciasFisicas;
            this.candidato.tipoDeAbastecimentoDeAgua = candidato.tipoDeAbastecimentoDeAgua;
            this.candidato.fonteDeEnergiaEletrica = candidato.fonteDeEnergiaEletrica;
            this.candidato.quantidadeDePessoasQueCompoemRendaFamiliar = candidato.quantidadeDePessoasQueCompoemRendaFamiliar;
            this.candidato.dispositivosDeAcessoInternet = candidato.dispositivosDeAcessoInternet;
            this.candidato.destinoDoLixo = candidato.destinoDoLixo;
    
            await this.popularCidades();
            if (candidato.cidade) {
                const cidade = this.cidades.filter(cd => cd.codigo == candidato.cidade.codigo);
                if (cidade && cidade.length > 0) {
                  this.candidato.cidade = cidade[0];
                }
              }
    
            this.bloquearCpf = true;
    
            if (candidato.cartaoSus) {
                this.bloquearSus = true;
            }
    
            if (candidato.nis) {
                this.bloquearNis = true;
            }
    
            this.$forceUpdate();
        }
    }

    abrirModalAnexarDocumento() {
        (this.$refs.modalDocumento as ModalAnexarDocumento & {
          openModal: () => void;
        }).openModal();
    }
}
