export enum eCorRaca {
    Branca = 1,
    Preta = 2,
    Parda = 3,
    Amarela = 4,
    Indigena = 5
}

export const CorRacaLabel = new Map<number, string>([
    [eCorRaca.Branca, 'Branca'],
    [eCorRaca.Preta, 'Preta'],
    [eCorRaca.Parda, 'Parda'],
    [eCorRaca.Amarela, 'Amarela'],
    [eCorRaca.Indigena, 'Indígena'],
]);